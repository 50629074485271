import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {
  InboxIcon,
  SparklesIcon,
  MenuAlt4Icon,
  SunIcon,
  UserGroupIcon,
  ScaleIcon,
  AnnotationIcon,
} from "@heroicons/react/outline";

const features = [
  {
    icon: <MenuAlt4Icon />,
    content: "Advance and encourage equality for women in the legal profession",
  },
  {
    icon: <ScaleIcon />,
    content:
      "Create and enhance awareness of contribution to the practice and development of the law by women.",
  },
  {
    icon: <SunIcon />,
    content:
      "Provide support and mentoring opportunities for women in the legal profession.",
  },
  {
    icon: <AnnotationIcon />,
    content:
      "Provide a forum for the exchange of information and opinions on aspects of the law relating to women.",
  },
  {
    icon: <UserGroupIcon />,
    content: "Provide a professional and social network for women lawyers.",
  },
];

const Features = () => {
  return (
    <div className="relative bg-white md:md:flex " id="about">
      <div className="w-full pl-6">
        <div className="hidden md:block h-[500px] overflow-visible">
          <StaticImage
            className=" h-full object-cover -ml-6 z-10 relative"
            src="https://images.pexels.com/photos/8111853/pexels-photo-8111853.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Support team"
          />
        </div>
        <div className="md:pl-60 py-12 lg:pt-24">
          <h2 className="text-4xl font-bold text-gray-500 uppercase">
            About TWL
          </h2>
          <p className="text-gray-500 leading-8 md:pr-24 pt-6 md:pt-12">
            Tasmanian Women Lawyers is an organisation committed to women in all
            aspects of the legal profession. This includes judicial officers,
            professionals, academics, graduates and students.
          </p>
          <svg
            width="216"
            height="12"
            viewBox="0 0 216 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=" md:mx-0 mt-12"
          >
            <path
              d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5Z"
              stroke="#95BE4A"
            />
            <path
              d="M210.5 11.5C213.261 11.5 215.5 9.03757 215.5 6C215.5 2.96243 213.261 0.5 210.5 0.5C207.739 0.5 205.5 2.96243 205.5 6C205.5 9.03757 207.739 11.5 210.5 11.5Z"
              stroke="#95BE4A"
            />
            <path
              d="M11.195 6.237H206.125"
              stroke="#95BE4A"
              stroke-miterlimit="10"
              stroke-dasharray="3.84 3.84"
            />
          </svg>
        </div>
      </div>
      <div className="flex-none bg-twl-green/40 w-12"></div>
      <div className="w-full bg-twl-purple-hover z-9 relative mx-auto px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
        <div className="md:pl-36 md:pr-48">
          <div>
            <p className="text-white text-xl font-semibold">
              Our objectives drive our support of TWL members in that we work
              to:
            </p>
          </div>
          <svg
            width="175"
            height="12"
            viewBox="0 0 175 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=" hidden sm:block my-12"
          >
            <path
              d="M6 11.5C9.03757 11.5 11.5 9.03757 11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5Z"
              stroke="white"
            />
            <path
              d="M169.5 11.5C172.261 11.5 174.5 9.03757 174.5 6C174.5 2.96243 172.261 0.5 169.5 0.5C166.739 0.5 164.5 2.96243 164.5 6C164.5 9.03757 166.739 11.5 169.5 11.5Z"
              stroke="white"
            />
            <path
              d="M11.195 6.237H164.604"
              stroke="white"
              stroke-miterlimit="10"
              stroke-dasharray="3.84 3.84"
            />
          </svg>
          <div className="mt-12 sm:mt-0">
            {features.map((feature) => {
              return (
                <div className="flex items-center gap-4 pb-8 sm:pb-12">
                  <div className="grid items-center justify-center h-16 w-16 px-4 rounded-full bg-white text-twl-green">
                    <div className="w-8 h-8">{feature.icon}</div>
                  </div>
                  <p className="text-lg sm:text-xl text-white">{feature.content}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
