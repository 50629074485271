import React from "react";

import header from "../assets/header.jpeg";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    <div class="relative">
      <div class="absolute inset-x-0 bottom-0  bg-gray-100 "></div>
      <div class="mx-auto">
        <div class="relative sm:overflow-hidden">
          <div class="absolute inset-0">
            <StaticImage
              class="h-full w-full object-cover brightness-50"
              src="../assets/header.jpeg"
              alt="People working on laptops"
            />
            <div class="absolute inset-0 "></div>
          </div>
          <div className="flex flex-col sm:grid sm:grid-cols-2 max-w-screen-2xl mx-auto px-4">
            <div class="relative mt-20 sm:mt-32 px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 class="text-left tracking-tight sm:text-6xl lg:text-7xl">
                <span class="block text-white uppercase font-medium  text-4xl sm:text-5xl">
                  Welcome to TWL
                </span>
                {/* <span class="block text-white font-heading uppercase text-4xl">
                  Coming Soon...
                </span> */}
              </h1>

              <p class="mt-6 sm:mt-10 max-w-lg mx-auto text-left font-medium text-lg sm:text-xl text-white sm:max-w-3xl">
                Tasmanian Women Lawyers has supported women in the Tasmanian
                legal profession for almost half a century. We are committed to
                the advancement of women in all aspects of the Tasmanian legal
                profession including judicial officers, professionals,
                academics, graduates and students.
              </p>
              <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex gap-4">
                {/* <div class="">
                  <AnchorLink
                    to="/#about"
                    className="flex items-center justify-center px-2 py-2 mb-4 border-2 border-twl-green rounded-md text-white bg-transparent hover:bg-twl-green hover:text-white transition ease-in-out text-lg font-heading  sm:px-8"
                  >
                    {" "}
                    TWL Mission Statement{" "}
                  </AnchorLink>
                </div> */}
                <div class="">
                  <Link
                    to="/become-a-member"
                    className="flex items-center justify-center px-2 py-2 border-2 border-twl-green rounded-md text-white font-semibold bg-transparent hover:bg-twl-green hover:text-white transition ease-in-out text-lg font-heading  sm:px-8"
                  >
                    {" "}
                    Become a Member{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-span-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
