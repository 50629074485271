/**
 * This is a singleton to ensure we only instantiate Stripe once.
 */


 import { loadStripe } from "@stripe/stripe-js"

 let stripePromise
 const getStripe = () => {
   if (!stripePromise) {
     stripePromise = loadStripe("pk_live_51L8cd7AHXOupBf4KzqqTCe2q5hW0lSM3SBhjbi80VIKJ5HPRfUYvWduFwbxwIr6zFzcZlCxakECgEKrF0XWd5whS00X6mod0HO")
   }
   return stripePromise
 }
 
 export default getStripe