import React from 'react'
import Contact from '../components/Contact'
import Features from '../components/Features'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Memberships from '../components/Memberships'
import Newsletter from '../components/Newsletter'
import SEO from '../components/Seo'

const Home = () => {
  return (
    <Layout>
      <SEO title= "Join Now" slug="/" image="../assets/header.jpeg"/>
      <main className=''>
        <Hero/>
        <Newsletter/>
        <Features/>
        <Memberships/>
        <Contact/>
      </main>
    </Layout>
  )
}

export default Home
