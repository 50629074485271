import React, { useState } from "react";
import getStripe from "../utils/stripejs";
import { Link } from "gatsby";

const buttonDisabledStyles = {
  opacity: "0.5",
  cursor: "not-allowed",
};

const formatPrice = (amount, currency) => {
  let price = (amount / 100).toFixed(2);
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  });
  return numberFormat.format(price);
};

const MemberCard = ({ product }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const price = product.id;
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price, quantity: 1 }],
      successUrl: `${window.location.origin}/`,
      cancelUrl: `${window.location.origin}/`,
    });

    if (error) {
      console.warn("Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex mb-6">
      <div className="flex-1 bg-white px-6 py-8 lg:p-12">
        <h3 className="text-2xl font-extrabold text-gray-500 sm:text-3xl">
          {product.product.name}
        </h3>
        <div className="mt-8">
          <div className="flex items-center">
            <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-twl-purple">
              Eligibility
            </h4>
            <div className="flex-1 border-t-2 border-gray-200" />
          </div>
          <p className="mt-4 text-base text-gray-500">
            {product.product.metadata.intro}
          </p>
          <ul className="pl-4 list-disc">
            <li className="empty:hidden mt-4 text-base text-gray-500">
              {product.product.metadata.list1}
            </li>
            <li className="empty:hidden mt-4 text-base text-gray-500">
              {product.product.metadata.list2}
            </li>
            <li className="empty:hidden mt-4 text-base text-gray-500">
              {product.product.metadata.list3}
            </li>
          </ul>
          <p className="empty:hidden mt-4 text-base text-gray-500">
            {product.product.metadata.outro}
          </p>
          <p className="empty:hidden mt-4 text-base text-gray-500">
            {product.product.metadata.note}
          </p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
      >
        <div className="mt-4 flex items-center justify-center text-5xl font-extrabold text-gray-900">
          <span>{formatPrice(product.unit_amount, product.currency)}</span>
        </div>
        <p className="mt-4 text-sm">
          <a href="#" className="font-medium text-gray-500 underline">
            /year
          </a>
        </p>
        <div className="mt-6">
          <div className="rounded-md shadow">
            <Link
              to="/become-a-member"
              disabled={loading}
              className="flex w-full items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-twl-purple hover:bg-twl-purple-hover transition ease-in-out"
            >
              Join Now
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MemberCard;
