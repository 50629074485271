import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { CheckIcon } from "@heroicons/react/solid";
import MemberCard from "./MemberCard";

const includedFeatures = [
  "Private forum access",
  "Member resources",
  "Entry to annual conference",
  "Official member t-shirt",
];

const incentives = [
  {
    name: "Free Shipping",
    description:
      "TWL host a number of exciting and informative networking and educational events throughout the year. These events are discounted for members.",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg",
  },
  {
    name: "24/7 Customer Support",
    description:
      "As members you'll have access to newsletters, events and information about employment opportunities.",
    imageSrc: "https://tailwindui.com/img/ecommerce/icons/icon-chat-light.svg",
  },
  {
    name: "Fast Shopping Cart",
    description:
      "By virtue of your membership you'll have the opportunity to get involved in some amazing committees and working groups.",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-fast-checkout-light.svg",
  },
  {
    name: "Gift Cards",
    description:
      "Membership to the association provides access to exclusive discounts and other benefits.",
    imageSrc:
      "https://tailwindui.com/img/ecommerce/icons/icon-gift-card-light.svg",
  },
];

export default function Memberships() {
  const data = useStaticQuery(graphql`
    {
      allStripePrice(sort: {order: ASC, fields: type }, filter: {unit_amount: {gt: 100}}, limit: 3) {
        edges {
          node {
            id
            active
            currency
            unit_amount
            type
            product {
              id
              name
              metadata {
                intro
                list1
                list2
                list3
                outro
                note
              }
            }
          }
        }
      }
    }
  `);

  const products = data.allStripePrice.edges;


  return (
    <>
      <div className="bg-twl-purple/5" id="memberships">
        <div className="max-w-7xl mx-auto pt-12 sm:pt-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="text-4xl font-bold uppercase text-twl-purple mx-auto sm:text-center mb-8 sm:mb-12">
            Why Become a Member
          </h2>
          <div className="pb-24 leading-8 text-lg sm:text-xl">
            <p className="text-twl-purple mx-auto sm:text-center">
              Membership is open to all women in the legal profession, and you
              are eligible to be a member if you are, or have been:
            </p>
            <ul className="text-twl-purple sm:px-6 py-6 list-disc mx-auto sm:text-center list-inside">
              <li className="pb-2">
                Admitted to practice as a barrister or solicitor of the Supreme
                Court of any State or Territory of Australia, or in any part of
                the British Commonwealth; or
              </li>
              <li>Are a law graduate of any university in Australia.</li>
            </ul>
            <p className="text-twl-purple mx-auto sm:text-center">
              You are also eligible to be an associate member if you are in your
              final year of a law degree or a degree combining law and another
              discipline.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-6">
            {incentives.map((incentive) => (
              <div
                key={incentive.name}
                className="bg-[#b7dd70] px-6 rounded-lg md:rounded-bl-none md:rounded-br-none"
              >
                <div className="h-12 w-12 flex items-center justify-center rounded-full bg-white text-center mx-auto text-twl-purple border border-twl-green -mt-6">
                  {" "}
                  <CheckIcon className="h-8 w-8 items-center justify-center" />
                </div>

                <p className="mt-2 text-md text-white py-6">
                  {incentive.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="pt-12 sm:pt-16 lg:pt-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="sm:text-center">
              <h2 className="text-3xl font-bold uppercase text-gray-500 sm:text-4xl lg:text-4xl mb-8 sm:mb-12">
                Become a Member
              </h2>
              <p className="text-lg sm:text-xl text-gray-500">
              Become a member below: Our membership year runs from 1 July to 30 June
              </p>
              {/* <p className="mt-4 text-lg sm:text-xl text-gray-500">
                The membership fee is an annual charge that is reviewed each
                year at our AGM in December.
              </p>
              <p className="mt-4 text-lg sm:text-xl text-gray-500">
                The current 2024 membership fees are as follows:
              </p> */}
            </div>
          </div>
        </div>
        <div className="mt-8 bg-twl-purple/5 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-white" />
            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              {products.map((product) => {
                return <MemberCard product={product.node} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
