import React from "react";

import { useForm } from "@formspree/react";

import { CheckCircleIcon, XIcon } from "@heroicons/react/solid";

const Newsletter = () => {
  const [state, handleSubmit] = useForm("mqknjdak");

  if (state.succeeded) {
    return (
      <div className="bg-gradient-to-b to-[#c5e48b] from-twl-green via-[#b7dd70]">
        <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-12 lg:px-8 lg:flex lg:items-center">
          <div className="lg:w-0 lg:flex-1">
            <h2
              className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
              id="newsletter-headline"
            >
              Sign up to the TWL Newsletter
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-white">
              Be the first to hear about new events, groups and offers and gain
              priority access to exciting TWL opportunities.
            </p>
          </div>
          <div className="mt-8 lg:mt-0 lg:ml-8">
            <form className="sm:flex" onSubmit={handleSubmit}>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email-address"
                type="email"
                autoComplete="email"
                required
                className="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white sm:max-w-xs rounded-md"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  disabled={state.submitting}
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-twl-purple hover:bg-twl-purple-hover transition ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-twl-purple"
                >
                  Sign up
                </button>
              </div>
            </form>
            <div className=" bg-twl-purple p-4 mt-4 rounded-lg">
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">
                    Your submission was successful!
                  </p>
                </div>
                <div className="ml-auto pl-3">
                  <div className="-mx-1.5 -my-1.5">
                    <button
                      onClick={() => window.location.reload(false)}
                      type="button"
                      className="inline-flex p-1.5 text-white rounded-lg hover:bg-ec-earth/20 focus:outline-none"
                    >
                      <span className="sr-only">Dismiss</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p className="mt-3 text-sm text-white">
              We care about the protection of your data. Read our{" "}
              <a href="#" className="text-white font-medium underline">
                Privacy Policy.
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-gradient-to-b to-[#c5e48b] from-twl-green via-[#b7dd70]">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:py-12 lg:px-8 lg:flex lg:items-center">
        <div className="lg:w-0 lg:flex-1">
          <h2
            className="text-3xl font-bold tracking-tight text-white sm:text-4xl"
            id="newsletter-headline"
          >
            Sign up to the TWL Newsletter
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-white">
            Be the first to hear about new events, groups and offers and gain
            priority access to exciting TWL opportunities.
          </p>
        </div>
        <div className="mt-8 lg:mt-0 lg:ml-8">
          <form className="sm:flex" onSubmit={handleSubmit}>
            <label htmlFor="email-address" className="sr-only">
              Email address
            </label>
            <input
              id="email-address"
              name="email-address"
              type="email"
              autoComplete="email"
              required
              className="w-full px-5 py-3 border border-transparent placeholder-gray-500 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white sm:max-w-xs rounded-md"
              placeholder="Enter your email"
            />
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                disabled={state.submitting}
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-twl-purple hover:bg-twl-purple-hover transition ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-twl-purple"
              >
                Sign up
              </button>
            </div>
          </form>
          <p className="mt-3 text-sm text-white">
            We care about the protection of your data. Read our{" "}
            <a href="#" className="text-white font-medium underline">
              Privacy Policy.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
